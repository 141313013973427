<template>
  <v-card
    :elevation="full ? '0' : '3'"
    >
    <v-card-title
      v-if="!full"
      >
      Usuarios
    </v-card-title>
    
    <v-card-text
      :class="full ? 'pa-0' : ''"
      >
      <v-dialog
        v-if="!full"
        v-model="createUser"
        width="800"
        >
        <CreateUser
          @reload="reload"
          @close="createUser = false"
          />
      </v-dialog>
      <v-card
        v-if="$vuetify.breakpoint.mdAndUp"
        flat
        >
        <v-card-text
          class="py-0 caption"
          >
          <v-row>
            <v-col
              cols="1"
              >
              ID
            </v-col>
            <v-col
              class="text-center"
              :cols="full ? 5 : 3"
              >
              Email
            </v-col>
            <v-col
              v-if="!full"
              cols="3"
              class="text-center"
              >
              Rol
            </v-col>
            <v-col
              class="text-center"
              :cols="full ? 5 : 3"
              >
              Contraseña
            </v-col>
            <v-col
              class="text-right"
              :cols="full ? 1 : 2"
              >
              <v-icon
                color="transparent"
                >
                mdi-delete
              </v-icon>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <template
        v-for="user in users"
        >
        <User
          :full="full"
          :user="user"
          :key="user.id"
          @reload="reload"
          />
      </template>

      <v-divider
        class="my-3"
        ></v-divider>

      <template
        v-if="!full"
        >
        <div
          class="pb-2 text-subtitle-1 primary--text"
          >
          Asignar usuario ya existente
        </div>

        <v-row>
          <v-col
            class="py-0"
            cols="12"
            md="5"
            >
            <v-autocomplete
              outlined
              dense
              hide-details
              label="Usuarios"
              v-model="newUser"
              :items="unasignedUsers"
              item-text="email"
              item-value="id"
              ></v-autocomplete>
          </v-col>

          <v-col
            class="py-0"
            cols="12"
            md="5"
            >
            <v-autocomplete
              outlined
              dense
              hide-details
              label="Tipo de usuario"
              v-model="newRole"
              :items="roles"
              item-text="name"
              item-value="value"
              ></v-autocomplete>
          </v-col>

          <v-col
            class="py-0 text-right"
            cols="12"
            md="2"
            >
            <v-btn
              text
              color="primary"
              :disabled="!newRole || !newUser"
              @click="addUser"
              >
              Asignar usuario
            </v-btn>
          </v-col>
        </v-row>

        <v-divider
          class="my-3"
          ></v-divider>

        <div
          class="mt-3 text-right"
          >
          <v-btn
            text
            color="success"
            @click="createUser = true"
            >
            Crear usuario
          </v-btn>
        </div>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import { Agency } from '@/graphql/queries/agencies'
import { Users } from '@/graphql/queries/users'
import { AddUser } from '@/graphql/mutations/users'
import { Roles } from '@/utils/roles'
import User from '@/components/admin/User'

const CreateUser = () => import('@/components/admin/CreateUser')

export default {
  data () {
    return {
      unasignedUsers: [],
      newUser: null,
      newRole: null,
      users: [],
      createUser: false,
      roles: Roles
    }
  },

  props: {
    full: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  components: { User, CreateUser },

  created () {
    if (!this.full) {
      this.fetchOrganisation()
    }

    this.fetchUsers ()
  },

  methods: {
    fetchUsers () {
      this.$apollo.query({
        query: Users,
        variables: {
          full: this.full
        },
        fetchPolicy: 'no-cache'
      }).then ( res => {
        if (this.full) {
          this.users = res.data.users
        } else {
          this.unasignedUsers = res.data.users
        }
      })
    },

    fetchOrganisation () {
      this.$apollo.query({
        query: Agency,
        fetchPolicy: 'no-cache'
      }).then( res => {
        this.users = res.data.agency.users
      })
    },
    
    addUser () {
      this.$apollo.mutate({
        mutation: AddUser,
        variables: {
          input: {
            id: this.newUser,
            role: this.newRole
          }
        }
      }).then ( res => {
        this.reload()
        this.newUser = null
        this.newRole = null
      })
    },

    reload () {
      this.fetchOrganisation()
      this.fetchUsers ()
    }
  }
}
</script>
