import gql from 'graphql-tag'

export const Agency = gql`
  query agency {
    agency {
      users {
        id
        email
        currentRoleName
      }
    }
  }
`
