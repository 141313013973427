<template>
  <v-dialog
    v-model="dialog"
    width="1090"
    scrollable
    >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        depressed
        outlined
        v-on="on"
        v-bind="attrs"
        >
        Ver LPUS
      </v-btn>
    </template>

    <v-card>
      <v-card-text
        class="text--primary d-flex flex-row pa-6"
        style="gap: 8px; position: relative"
        >
        <div
          style="min-width: 130px; position: sticky; top: 0"
          >
          <div
            class="font-weight-medium body-1"
            >
            Listado de LPUs
          </div>

          <v-list
            >
            <v-list-item-group
              v-model="selected"
              color="primary"
              >
              <v-list-item
                v-for="lpu in lpus"
                >
                <v-list-item-title>
                  {{ (new Date(lpu.startsAt)).toLocaleDateString() }}
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
        
        <v-divider
          vertical
          ></v-divider>

        <div
          class="grow"
          style="max-width: 900px"
          >
          <div
            v-if="lpus[selected]"
            >
            <div
              class="font-weight-medium body-1 mb-2 d-flex"
              >
              <div>
                {{ (new Date(lpus[selected].startsAt)).toLocaleDateString() }} <template v-if="lpus[selected].endsAt"> al {{ (new Date(lpus[selected].endsAt)).toLocaleDateString() }}</template>
              </div>
              
              <v-spacer></v-spacer>

              <div>
                <v-btn
                  small
                  depressed
                  color="error"
                  @click="remove"
                  >
                  Eliminar LPU
                </v-btn>
              </div>
            </div>

            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Tecnología
                    </th>
                    <th class="text-left">
                      Escenario
                    </th>
                    <th class="text-left">
                      Moneda
                    </th>
                    <th class="text-left">
                      Precio
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, i) in lpus[selected].nokiaItems"
                    :key="item.name"
                    :class="i % 2 ? 'grey lighten-3' : ''"
                    >
                    <td>{{ item.technology }}</td>
                    <td
                      style="max-width: 450px"
                      >
                      {{ item.scene }}
                    </td>
                    <td>{{ item.currency }}</td>
                    <td>{{ parseFloat(item.price).toLocaleString('es-CL') }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>

          <v-card
            v-else
            flat
            color="grey lighten-3"
            >
            <v-card-text
              class="text--primary"
              >
              Seleccione una LPU para continuar.
            </v-card-text>
          </v-card>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { Lpus } from '@/graphql/queries/activities'
import { RemoveLpu } from '@/graphql/mutations/activities'

export default {
  data: () => ({
    lpus: [],
    dialog: false,
    selected: null
  }),

  watch: {
    dialog (val) {
      if (val) {
        this.fetchLpus()
      }
    }
  },

  methods: {
    fetchLpus () {
      this.$apollo.query({
        query: Lpus,
        variables: {
          provider: 'nokia'
        },
        fetchPolicy: 'no-cache'
      }).then ( res => {
        this.lpus = res.data.lpus
      })
    },

    remove () {
      this.$apollo.mutate({
        mutation: RemoveLpu,
        variables: {
          input: {
            id: this.lpus[this.selected].id
          }
        }
      }).then( res => {
        this.fetchLpus()
        this.selected = null
      })
    }
  }
}
</script>
