<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          md="6"
          >
          <v-autocomplete
            :items="operators"
            item-text="name"
            item-value="id"
            outlined
            solo
            flat
            label="Seleccionar operadora"
            v-model="operator"
            hide-details
            ></v-autocomplete>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          md="6"
          >
          <v-card
            outlined
            >
            <v-card-title>
              Importar LPU Huawei
            </v-card-title>

            <v-card-text>
              <v-file-input
                truncate-length="15"
                outlined
                solo
                flat
                label="Seleccionar archivo..."
                v-model="huaweiFile"
                ></v-file-input>

              <div
                class="d-flex justify-end"
                style="gap: 12px"
                >

                <huawei-list></huawei-list>

                <v-btn
                  color="primary"
                  depressed
                  :disabled="!huaweiFile || loading || !operator"
                  :loading="loading"
                  @click="importHuawei"
                  >
                  Importar
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col
          cols="12"
          md="6"
          >
          <v-card
            outlined
            >
            <v-card-title>
              Importar LPU Nokia
            </v-card-title>

            <v-card-text>
              <v-file-input
                truncate-length="15"
                outlined
                solo
                flat
                label="Seleccionar archivo..."
                v-model="nokiaFile"
                ></v-file-input>

              <div
                class="d-flex justify-end"
                style="gap: 12px"
                >

                <nokia-list></nokia-list>

                <v-btn
                  color="primary"
                  depressed
                  :disabled="!nokiaFile || loading || !operator"
                  :loading="loading"
                  @click="importNokia"
                  >
                  Importar
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import HuaweiList from '@/components/admin/lpus/Huawei'
import NokiaList from '@/components/admin/lpus/Nokia'
import { HuaweiLpuImporter, NokiaLpuImporter } from '@/graphql/mutations/importations'
import { Operators } from '@/graphql/queries/operators'

export default {
  data: () => ({
    huaweiFile: null,
    nokiaFile: null,
    loading: false,
    operator: null,
    operators: []
  }),

  created () {
    this.fetchOperators()
  },

  methods: {
    importHuawei () {
      this.loading = true

      this.$apollo.mutate({
        mutation: HuaweiLpuImporter,
        variables: {
          input: {
            file: this.huaweiFile,
            operatorId: this.operator
          }
        }
      }).then ( res => {
        this.huaweiFile = null

        this.$nextTick( () => {
          this.$forceUpdate ()
          this.loading = false
        })
      })
    },

    importNokia () {
      this.loading = true

      this.$apollo.mutate({
        mutation: NokiaLpuImporter,
        variables: {
          input: {
            file: this.nokiaFile,
            operatorId: this.operator
          }
        }
      }).then ( res => {
        this.nokiaFile = null

        this.$nextTick( () => {
          this.$forceUpdate ()
          this.loading = false
        })
      })
    },

    fetchOperators () {
      this.$apollo.query({
        query: Operators
      }).then ( res => {
        this.operators = res.data.operators
      })
    }
  },

  components: {
    HuaweiList,
    NokiaList
  }
}
</script>
