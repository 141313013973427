<template>
  <v-container
    class="justify-center mx-auto fill-height d-flex align-center"
    style="max-width: 800px"
    >
    <div
      class="font-weight-bold text-h1 white--text"
      style="position: absolute; left: 12px; top: 12px"
      >
      <v-img
        width="400"
        :src="require('@/assets/img/logo-svm-grande.png')"
        ></v-img>
    </div>

    <div
      class="font-weight-bold text-h1 white--text"
      style="position: absolute; right: 12px; bottom: 12px"
      >
      Tiantar
    </div>

    <v-card
      class="grow"
      rounded="lg"
      color="transparent"
      >
      <v-card-title
        class="py-2 lower-opacity white--text primary"
        >
        Seleccionar proyecto
      </v-card-title>

      <v-card-text
        style="height: 300px"
        class="lower-background-opacity text-h6 black--text font-weight-regular pt-3"
        >
        <div
          class="d-flex flex-column justify-space-between fill-height"
          >
          <v-autocomplete
            solo
            flat
            outlined
            :items="projects"
            item-text="name"
            item-value="id"
            label="Proyecto"
            class="rounded-lg"
            v-model="project"
            ></v-autocomplete>

          <v-card
            style="gap: 12px"
            class="d-flex justify-space-between"
            flat
            :disabled="!project"
            >
            <v-btn
              color="primary"
              class="rounded-lg grow py-5 justify-start"
              :to="{ name: 'rollout', params: { id: project } }"
              >
              <img
                src="@/assets/icons/tiantar-rollout.svg"
                height="36px"
                width="36px"
                ></img>
              Rollout
            </v-btn>

            <v-btn
              color="primary"
              class="rounded-lg grow py-5 justify-start"
              :to="{ name: 'dashboards', params: { id: project } }"
              >
              <img
                src="@/assets/icons/tiantar-dashboard.svg"
                height="36px"
                width="36px"
                ></img>
              Dashboard
            </v-btn>

            <v-btn
              color="primary"
              class="rounded-lg grow py-5 justify-start"
              :to="{ name: 'reports', params: { id: project } }"
              >
              <img
                src="@/assets/icons/tiantar-reports.svg"
                height="36px"
                width="36px"
                ></img>
              Reportes
            </v-btn>

            <v-btn
              color="primary"
              class="rounded-lg grow py-5 justify-start"
              :to="{ name: 'repository', params: { id: project } }"
              >
              <img
                src="@/assets/icons/tiantar-repository.svg"
                height="36px"
                width="36px"
                ></img>
              Repositorio
            </v-btn>
          </v-card>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { Projects } from '@/graphql/queries/projects'

import { mapGetters } from 'vuex'

export default {
  name: 'Home',

  data: () => ({
    projects: [],
    project: null,
  }),

  created () {
    this.fetchProjects ()
  },

  computed: {
    ...mapGetters(['currentUser'])
  },

  methods: {
    fetchProjects () {
      this.$apollo.query({
        query: Projects,
      }).then ( res => {
        var last_project = ''

        res.data.projects.forEach ( project => {
          if (last_project != project.operator.name) {
            this.projects.push({ header: project.operator.name })
            last_project = project.operator.name
          }

          this.projects.push(project)
        })
      })
    }
  }
}
</script>
