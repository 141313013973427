<template>
  <v-card
    outlined
    :disabled="currentUser.id == user.id || currentUser.currentRoleName == 'management' && user.currentRoleName == 'shibui'"
    class="mb-3"
    >
    <v-card-text>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        >
        <v-row
          class="align-center"
          >
          <v-col
            v-if="$vuetify.breakpoint.mdAndUp"
            class="py-0"
            cols="1"
            >
            {{ user.id }}
          </v-col>
          <v-col
            class="text-center py-0"
            cols="12"
            :md="full ? 5 : 3"
            >
            <v-text-field
              label="Email"
              v-model="attributes.email"
              placeholder="test@test.com"
              :rules="rules.email"
              outlined
              dense
              hide-details="auto"
              >
            </v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="text-center py-0"
            v-if="!full"
            >
            <v-autocomplete
              label="Tipo de usuario"
              v-model="attributes.role"
              :items="roles"
              item-text="name"
              item-value="value"
              outlined
              dense
              hide-details="auto"
              >
            </v-autocomplete>
          </v-col>
          <v-col
            class="text-center py-0"
            cols="12"
            :md="full ? 5 : 3"
            >
            <v-text-field
              dense
              label="Contraseña"
              :type="showPass ? 'text' : 'password'"
              :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPass = !showPass"
              v-model="attributes.password"
              outlined
              hide-details="auto"
              >
            </v-text-field>
          </v-col>
          <v-col
            cols="12"
            :md="full ? 1 : 2"
            class="text-right py-0"
            >
            <v-btn
              icon
              small
              @click="removeUser"
              :disabled="currentUser.id == user.id || user.currentRoleName == 'basit'"
              v-if="!full"
              >
              <v-icon
                color="red"
                small
                >
                mdi-block-helper
              </v-icon>
            </v-btn>

            <v-btn
              icon
              small
              @click="deleteUser"
              :disabled="currentUser.id == user.id"
              v-if="full"
              >
              <v-icon
                color="red"
                >
                mdi-delete
              </v-icon>
            </v-btn>

            <v-btn
              icon
              small
              @click="saveChanges"
              :disabled="roleChange"
              >
              <v-icon
                color="success"
                >
                mdi-check
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <v-snackbar
      v-model="saved"
      :timeout="4000"
      color="success darken-1"
      >
      <v-icon class="mr-3">mdi-check</v-icon> ¡Actualización exitosa!
    </v-snackbar>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { DeleteUser, EditOrganisationUser, RemoveUser } from '@/graphql/mutations/users'
import { Email, EditPassword } from '@/utils/rules'
import { Roles } from '@/utils/roles'

export default {
  data () {
    return {
      showPass: false,
      attributes: {
        email: null,
        password: null,
        role: null,
      },
      rules: {
        required: [
          v => !!v || 'Campo obligatorio'
        ],
        email: [
          v => !!v || 'Campo obligatorio'
        ],
        password: [
          v => !!v || 'Campo obligatorio'
        ]
      },
      saved: false,
      valid: false,
      roles: Roles
    }
  },
  
  mounted () {
    this.attributes.role = this.user.currentRoleName
    this.attributes.email = this.user.email
  },

  props: {
    user: {
      required: true,
      type: Object
    },
    full: {
      required: false,
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters(['currentUser']),

    roleChange () {
      return this.user.currentRoleName == this.attributes.role && this.user.email == this.attributes.email && this.attributes.password == null
    }
  },

  methods: {
    removeUser () {
      this.$apollo.mutate({
        mutation: RemoveUser,
        variables: {
          input: {
            id: this.user.id
          }
        }
      }).then( res => {
        this.$emit('reload')
      })
    },

    deleteUser () {
      this.$apollo.mutate({
        mutation: DeleteUser,
        variables: {
          input: {
            id: this.user.id
          }
        }
      }).then( res => {
        this.$emit('reload')
      })
    },

    saveChanges () {
      if (this.$refs.form.validate()) {
        this.$apollo.mutate({
          mutation: EditOrganisationUser,
          variables: {
            input: {
              id: this.user.id,
              role: this.attributes.role,
              email: this.attributes.email,
              password: this.attributes.password
            }
          }
        }).then( res => {
          this.saved = true
          this.$emit('reload')
          setTimeout ( () => {
            this.saved = false
          }, 4000)
        })
      }
    }
  }
}
</script>
