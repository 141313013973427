<template>
  <v-main
    v-if="currentUser"
    class="fill-height"
    >
    <v-container
      class="mx-auto"
      style="max-width: 1400px"
      >
      <div>
        <v-card
          elevation="3"
          >
          <v-card-title>
            Mis datos
          </v-card-title>

          <v-card-text>
            <v-form
              v-model="valid"
              ref="form"
              lazy-validation
              >
              <v-text-field
                outlined
                v-model="user.email"
                label="Email"
                :rules="[
                v => !!v || 'Campo obligatorio'
                ]"
                ></v-text-field>

              <v-text-field
                outlined
                v-model="user.password"
                label="Contraseña"
                type="password"
                ></v-text-field>

              <v-text-field
                outlined
                v-model="user.currentPassword"
                label="Contraseña actual"
                type="password"
                :rules="[
                v => !!v || 'Campo obligatorio'
                ]"
                ></v-text-field>
            </v-form>

            <div
              class="text-right"
              >
              <v-btn
                tile
                color="primary"
                @click="editUser"
                >
                Editar
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </div>

      <div
        v-if="['shibui', 'management'].includes(currentUser.currentRoleName)"
        class="mt-3"
        >
        <Users />
      </div>

      <div
        v-if="['shibui'].includes(currentUser.currentRoleName)"
        class="mt-3"
        >
        <importers></importers>
      </div>
    </v-container>
  </v-main>
</template>

<script>
import { mapGetters } from 'vuex'
import { EditUser } from '@/graphql/mutations/users'

import Users from '@/components/admin/Users'
import Importers from '@/components/admin/Importers'

export default {
  data () {
    return {
      valid: true,
      user: {
        email: null,
        password: null,
        currentPassword: null
      }
    }
  },

  computed: {
    ...mapGetters(['currentUser'])
  },

  components: { Users, Importers },

  watch: {
    currentUser () {
      this.user.email = this.currentUser.email
    }
  },

  mounted () {
    if (this.currentUser) {
      this.user.email = this.currentUser.email
    }
  },

  methods: {
    editUser () {
      if (this.$refs.form.validate()) {
        this.$apollo.mutate({
          mutation: EditUser,
          variables: {
            input: {
              id: this.currentUser.id,
              password: this.user.currentPassword,
              attributes: {
                email: this.user.email,
                password: this.user.password
              }
            }
          }
        }).then( res => {
          this.$router.go()
        })
      }
    }
  }
}
</script>
