export const Roles = [
  {
    name: 'Dueño',
    value: 'shibui'
  },  
  {
    name: 'Default',
    value: 'default'
  },  
  {
    name: 'Gerencia',
    value: 'management'
  },
  {
    name: 'Rollout',
    value: 'rollout'
  },
  {
    name: 'Documentación',
    value: 'documentation'
  },
  {
    name: 'Facturación',
    value: 'finance'
  },
  {
    name: 'Encargado de bodega',
    value: 'warehouse'
  },
]
