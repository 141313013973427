import gql from 'graphql-tag'

export const HuaweiPricing = gql`
  query huaweiPricing {
    huaweiPricing {
      id
      number
      category
      activityType
      item
      description
      uom
      price
      appliesZone
    }
  }
`

export const NokiaPricing = gql`
  query nokiaPricing($id: ID!) {
    nokiaPricing(id: $id) {
      id
      scene
      technology
    }
  }
`

export const Lpus = gql`
  query lpus($provider: String!) {
    lpus(provider: $provider) {
      id
      endsAt
      startsAt

      huaweiItems {
        id
        number
        category
        activityType
        item
        description
        uom
        price
        appliesZone
      }

      nokiaItems {
        id
        scene
        technology
        tss
        currency
        activityType
        price
      }
    }
  }
`
