import gql from 'graphql-tag'

export const Users = gql`
  query users($full: Boolean) {
    users(full: $full) {
      id
      email
    }
  }
`
