import messages from '../i18n'
import { LocaleService } from '../services/storageService'

const locale = LocaleService.getLocale()

function getMsg (code) {
  let lcl = (Object.keys(messages).includes(locale)) ? locale : 'es'
  return messages[lcl].validation[code]
}

export const Email = [
  v => !!v || getMsg('is_required'),
  v => /.+@.+/.test(v) || getMsg('must_be_a_valid_email'),
]

export const Password = [
  v => !!v || getMsg('is_required'),
  v => (v || '').length >= 6 || getMsg('must_be_gte_to_6_chars'),
]

export const EditPassword = [
  v => (v || 'aaaaaa').length >= 6 || getMsg('must_be_gte_to_6_chars'),
]

export const Username = [
  v => !!v || getMsg('is_required'),
  v => v.length >= 8 || getMsg('must_be_gte_to_8_chars'),
]
