import gql from 'graphql-tag'

export const CloseActivity = gql`
  mutation closeActivity($input: CloseActivityInput!) {
    closeActivity(input: $input) {
      success
    }
  }
`

export const RemoveLpu = gql`
  mutation removeLpu($input: RemoveLpuInput!) {
    removeLpu(input: $input) {
      success
    }
  }
`
